import { render, staticRenderFns } from "./SimpleDoctorSelect.vue?vue&type=template&id=556b2b92&scoped=true"
import script from "./SimpleDoctorSelect.vue?vue&type=script&lang=js"
export * from "./SimpleDoctorSelect.vue?vue&type=script&lang=js"
import style0 from "./SimpleDoctorSelect.vue?vue&type=style&index=0&id=556b2b92&prod&lang=scss"
import style1 from "./SimpleDoctorSelect.vue?vue&type=style&index=1&id=556b2b92&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "556b2b92",
  null
  
)

export default component.exports