<template>
  <div class="field-row">
    <div class="flex-fill">
      <multiselect
        class="form-control"
        v-model="value.requester_doctor_id"
        label="name"
        track-by="id"
        :options="doctors"
        :showLabels="false"
        :allowEmpty="true"
        placeholder="Médico..."
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template #singleLabel="props">
          <div class="doctor-name">
            {{props.option.name}}
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="doctor-option">
            <Avatar size="30px" :src="props.option.picture" />
            <div>{{ props.option.name }}</div>
          </div>
        </template>

        <template slot="noOptions">
          Nenhuma opção
        </template>

        <template slot="noResult">
          Nenhum resultado
        </template>
      </multiselect>
    </div>
  </div>

</template>
<script>

export default {
  name: "SimpleDoctorSelect",
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Avatar: () => import('@/assets/icons/avatar.svg'),
  },
  props: {
    value: Object,
    clinic_id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      doctors: []
    }
  },
  mounted() {
    this.getDoctors()
  },

  methods: {
    /**
     * Get doctor's list to populate the Select component
     */
    getDoctors() {
      this.api.getDoctors(this.clinic_id)
      .then(({ data }) => {
        this.doctors = data.doctors.map(doctor => ({
          id: doctor.id,
          name: doctor.name,
          picture: doctor.picture,
        }))
      })
      .catch(err => {
        this.$toast.error('Erro ao listar Médicos, por favor, atualize o sistema. Se o problema persistir, contate o Suporte e relate a Ocorrência')
        console.error(err.message)
      })
    },
  },
  watch: {

  }
}
</script>

<style lang="scss">
.doctors-combo {
  border: 1px solid var(--neutral-300) !important;
  min-height: 38px;
}
.field-row {
  margin-bottom: 10px;

  .form-control {
    padding: 0;
  }
  .multiselect {
    border: 1px solid #C6CEEB;
    border-radius: 8px;
    height: 38px;

    .multiselect__tags{
      height: 38px;
    }

    .multiselect__single {
      font-size: 16px;
      font-weight: 400;

      .doctor-name {
        display: flex;
        align-items: center;
        color: #525C7A;
        font-size: 16px;
        font-weight: 650;
      }
    }

    .multiselect__option {
      svg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }

      .doctor-option {
        display: flex;
        align-items: center;
      }
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: #C6CEEB;
      border-radius: 100px;
    }

    &.multiselect--disabled {
      opacity: 1;

      .multiselect__select {
        background-color: #ffffff;
      }
    }
  }

  .flex-fill {

    input.form-control {
      height: 38px !important;
    }

  }
}
</style>

<style scoped lang="scss">
 .option__title {
   padding: 7px 10px;
   border-radius: 15px;
 }
</style>